require('./bootstrap');

import flatpckr from 'flatpickr';
const Russian = require("flatpickr/dist/l10n/zh-tw.js").default.zh_tw;

flatpickr.localize(Russian);
window.flatpckr = flatpckr;

import Alpine from 'alpinejs';
window.Alpine = Alpine;
Alpine.start();
